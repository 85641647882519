import Home from "../pages/Home";
const routes = [
  {
    path: "/",
    element: Home
  },
  {
    path: "/home",
    element: Home
  },
];

export default routes;
